import { Button } from "theme-ui";
import header_image from "../../../../static/LeagueGenAlg/images/header_image.jpg";
import given_team from "../../../../static/LeagueGenAlg/images/given_team.png";
import generated_teams from "../../../../static/LeagueGenAlg/images/generated_teams.png";
import crossover from "../../../../static/LeagueGenAlg/images/crossover.png";
import mutation from "../../../../static/LeagueGenAlg/images/mutation.png";
import fitness_function from "../../../../static/LeagueGenAlg/images/fitness_function.png";
import selection from "../../../../static/LeagueGenAlg/images/selection.png";
import performance from "../../../../static/LeagueGenAlg/images/performance.png";
import * as React from 'react';
export default {
  Button,
  header_image,
  given_team,
  generated_teams,
  crossover,
  mutation,
  fitness_function,
  selection,
  performance,
  React
};