import { Button } from "theme-ui";
import squares from "../../../../static/processing/squares.png";
import cubes from "../../../../static/processing/cubes.png";
import joydivision from "../../../../static/processing/joydivision.png";
import lines from "../../../../static/processing/lines.png";
import gen1 from "../../../../static/processing/gen1.png";
import gen2 from "../../../../static/processing/gen2.png";
import gen3 from "../../../../static/processing/gen3.png";
import gen4 from "../../../../static/processing/gen4.png";
import polarpts1 from "../../../../static/processing/polarpts1.png";
import * as React from 'react';
export default {
  Button,
  squares,
  cubes,
  joydivision,
  lines,
  gen1,
  gen2,
  gen3,
  gen4,
  polarpts1,
  React
};