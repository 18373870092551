import { jsx, Button, Text, Link as TLink } from "theme-ui";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  jsx,
  Button,
  Text,
  TLink,
  Link,
  React
};